<template>
  <div class="login-binding-main">
    <div class="top-line">
      <van-icon name="manager" color="#1989fa" />
      <span>请填写您要绑定的易缴费平台账户信息</span>
    </div>
    <div class="form-main">
      <img src="@/assets/newImages/login2.png" class="bg-img">
      <div class="form-input">
        <input v-model="account" type="text" placeholder="学工号">
      </div>
      <div class="form-input">
        <input v-model="password" type="password" placeholder="缴费平台密码">
      </div>
      <van-button type="info" class="form-btn" @click="submitBtn">绑定</van-button>
    </div>
  </div>
</template>

<script>
import { boundUserAccount, queryUserNameAndClass } from '@/api/password'
import Cookies from 'js-cookie'
import store from '@/store'
import md5 from 'js-md5'
import Vue from 'vue'
import { Dialog, Toast } from 'vant'
Vue.prototype.$md5 = md5
export default {
  name: 'LoginBinding',
  data() {
    return {
      account: '',
      password: ''
    }
  },
  methods: {
    submitBtn() {
      if (!this.account || !this.password) {
        Toast.fail('信息不能为空')
        return false
      }
      var data = {}
      data.accountNum = this.account
      data.password = this.$md5(this.password)
      var storage = window.localStorage
      if (storage.subAppId != null && storage.subAppId !== 'null') {
        data.appId = storage.subAppId || storage.appId
      } else {
        data.appId = storage.appId
      }
      data.openId = storage.openId
      data.schoolCode = 'sc0000001'
      data.dataSource = store.state.settings.dataSource
      var model = this.$route.query.model
      var projectId = this.$route.query.projectId
      queryUserNameAndClass(data).then(res => {
        Dialog.confirm({
          title: '提示',
          message: '班级：' + res.data.className + '；姓名：' + res.data.name + '是否继续绑定'
        }).then(() => {
          // on confirm
          boundUserAccount(data).then(res => {
            this.$store.dispatch('user/refreshToken', res.data.token).then(() => {
              Cookies.set('qy_reimbursement_token', res.data.token)
              this.$store.dispatch('user/getInfo')
              this.$store.state.user.accountNum = this.account
              window.localStorage.setItem('accountNum', this.account)
              if (model !== '' && model !== null && model !== undefined && projectId !== '' && projectId !== null && projectId !== undefined) {
                this.$router.push({ path: model + 'Pay', query: { projectId: projectId }})
              } else {
                this.$router.push('/home')
              }
            })
          })
        }).catch(() => {
          // on cancel
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.login-binding-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 17px;
}
.top-line{
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 48px 0 24px;
}
.form-main{
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(47, 159, 242, 0.15);
    border-radius: 8px;
    padding: 14px 24px 34px;
    font-size: 14px;
    position: relative;
    // background-image: url('../../assets/newImages/login2.png');
    // background-repeat:no-repeat;
    // background-position: 80% 15%;
    // background-size: 142px 114px;
    .bg-img{
        position: absolute;
        right: 10px;
        top: 10px;
        float: right;
        width: 142px;
        height: 114px;
        opacity:0.4;
    }
    .form-input{
        position: relative;
        height: 38px;
        line-height: 38px;
        border-bottom: 1px solid #D0D0D0;
        margin-bottom: 9px;
        input{
            width: 100%;
            height: 38px;
            border: 0;
            background-color: rgba(255,255,255,0);
        }
    }
    .form-btn{
        margin-top: 35px;
        width: 100%;
    }
}
.btn-icon{
    width: 96px;
    position: fixed;
    bottom: 26px;
}
</style>
